<template>
  <div>
    <div v-if="visible">
      <span
        v-if="price < regularPrice"
        class="text-primary discount"
      >
        {{ percent(((regularPrice - price) / regularPrice) * 100 * -1) }}&nbsp;
      </span>
      <span class="text-weight-medium no-margin price">
        {{ currency(price) }}
      </span>
      <div
        v-if="price < regularPrice"
        class="text-body2"
      >
        Regular Price:
        <span class="text-strike">{{ currency(regularPrice) }}</span>
      </div>
    </div>

    <div
      v-else
      class="row items-center q-gutter-md"
    >
      <q-skeleton
        type="text"
        animation="none"
        width="125px"
        class="placeholder"
        square
      />

      <q-btn
        :icon="mdiHelp"
        color="secondary"
        flat
        round
        @click="onClick"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mdiHelp } from '@quasar/extras/mdi-v7';

const { product, variation, quoted } = useProduct()!;
const { currency, percent } = useFormatting();
const visible = ref<boolean>(!product.minimumAdvertisedPricePolicy || quoted.value);
const { mapDialog } = useDialogs();

const price = computed(() => variation.value?.price ?? product.price);

const regularPrice = computed(
  () => variation.value?.regularPrice ?? product.regularPrice,
);

const onClick = () => {
  if (product.minimumAdvertisedPricePolicy == null) {
    return;
  }

  if (product.minimumAdvertisedPricePolicy == 'Click') {
    visible.value = true;
    return;
  }

  mapDialog(product.minimumAdvertisedPricePolicy);
};
</script>

<style lang="scss" scoped>
@use "sass:map";

.price {
  font-size: map.get($h5, 'size');

  @media (min-width: $breakpoint-md-min) {
    font-size: map.get($h4, 'size');
  }
}

.placeholder {
  font-size: map.get($h4, 'size');

  @media (min-width: $breakpoint-md-min) {
    font-size: map.get($h3, 'size');
  }
}

.discount {
  font-size: map.get($h6, 'size');

  @media (min-width: $breakpoint-md-min) {
    font-size: map.get($h5, 'size');
  }
}
</style>
