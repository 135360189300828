<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-if="status == 'pending'">
      <q-skeleton
        v-for="line in 12"
        :key="line"
        type="text"
      />
    </div>
    <div v-else-if="description?.value">
      <div
        class="text-content"
        v-html="description.value"
      />
    </div>
    <div
      v-else
      class="text-content"
    >
      Shop with KYGUNCO for the best selection, competitive pricing, and exceptional
      customer service. Experience the difference that comes with buying from a trusted
      firearms retailer with over 20 years of experience in the industry.
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
  },
});

const { product } = useProduct()!;

const { $ecommerce } = useNuxtApp();

const { data: description, status } = await useAsyncData(
  `products_${product.id}_description`,
  () => $ecommerce.fetch<{ value: string }>(`/products/${product.id}/description`),
  {
    lazy: true,
    getCachedData: getCachedPayload,
  },
);
</script>
